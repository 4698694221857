<template>
  <HXContentCard title="Mantenimientos">
    <div class="text-right px-4 mt-3 mb-3">
      <div class="row">
        <div class="col-12 col-md-10">
          <CardTable :data="dataCards" />
        </div>
        <div class="col-12 col-md mt-3 mt-md-0">
          <BtnAddCardTable text="Agregar Mantenimiento" @click="$router.push('AgregarMantenimiento')" />
        </div>
      </div>
    </div>
    <div class='col-12 col-md-4 pb-3' v-if="persona.rol.nivel === 4">
      <label for="Nombre">Empresa *</label>
      <b-form-select name='Impuesto' v-model="EmpresaSeleccionada" :options='EmpresasLista' :value-field="'ID'"
        :text-field="'Nombre'" @change="$refs.dataTableLotes.$emit('actualizar');"
        placeholder='-- Seleccionar una empresa --' required>
        <template #selected="{ option }">
          {{ option?.Impuesto || '-- Seleccionar una empresa --' }}
        </template>
        <template #first>
          <b-form-select-option value="null">-- Seleccionar una empresa --</b-form-select-option>
        </template>
      </b-form-select>
    </div>
    <div class="table-responsive px-4 mt-3">
      <DataTableLotes ref='dataTableLotes' :fetch-data="fetchData" :columns="columns" :busqueda-boton="true">
        <template v-slot:id="{ row }">
          <div class="d-flex justify-content-center px-0 mx-0">
            <vs-button v-if='row.nombreTecnico != "Sin asignar"' v-b-tooltip="{
              title: `Proceso`,
              placement: 'top',
              customClass: 'font_tooltip',
            }" warn icon @click="
              $router.push(`DetalleMantenimiento/${row.id}`)">
              <i class="fa-solid fa-list-check"></i>
            </vs-button>
            <vs-button v-b-tooltip="{
              title: `Editar`,
              placement: 'top',
              customClass: 'font_tooltip',
            }" :disabled='row.estado === "Finalizado"' success icon
              @click="$router.push(`EditarMantenimiento/${row.id}`)">
              <i class="fas fa-solid fa-pen"></i>
            </vs-button>
            <vs-button v-b-tooltip="{
              title: `Cerrar Mantenimiento`,
              placement: 'top',
              customClass: 'font_tooltip',
            }" :disabled='row.estado === "Finalizado" || row.nombreTecnico == "Sin asignar"' @click="CambiarEstado(row.id)">
              <i class="fas fa-solid fa-lock"></i>
            </vs-button>
            <vs-button warn v-if='row.nombreTecnico == "Sin asignar" && persona.rol.nivel == 8' v-b-tooltip="{
              title: `Asignar Técnico`,
              placement: 'top',
              customClass: 'font_tooltip',
            }" @click.prevent="() => modal(row.id)" icon>
              <i class="fa-regular fa-user"></i>
            </vs-button>
            <vs-button danger v-b-tooltip="{
              title: `Eliminar`,
              placement: 'top',
              customClass: 'font_tooltip',
            }" @click="eliminarMantenimiento(row.id)" icon>
              <i class="fa-solid fa-trash"></i>
            </vs-button>
          </div>
        </template>
      </DataTableLotes>
    </div>
    <b-modal id="modal-evidencias" size="lg" hide-footer>
      <h2 class="font-weight-bold">Asignar Técnico</h2>
      <form @submit.prevent="AsignarTecnico">
        <div class="mb-3 w-lg-50">
          <label for='tecnico' class="form-label">Técnico</label>
          <b-form-select id="tecnico" :options="ListasTecnicos" required value-field="id" text-field="nombre"><template
              #first>
              <b-form-select-option selected :value="null" disabled>-- selecciona una opción
                --</b-form-select-option>
            </template></b-form-select>
        </div>
        <div class="d-flex justify-content-end">
          <vs-button type="submit">Agregar</vs-button>
        </div>
      </form>
    </b-modal>
  </HXContentCard>
</template>
<script>
  import DataTableLotes from "@/components/Datatable/DataTableLotes.vue";
  import Swal from 'sweetalert2';
  import CardTable from "../../../../components/cardTable/CardTable.vue";
  import BtnAddCardTable from "../../../../components/btnCardTable/btnAddCardTable.vue";

  export default {
    name: "DataTable",
    components: {
      DataTableLotes,
      CardTable,
      BtnAddCardTable
    },
    data() {
      return {
        options: [
          { id: 1, value: null, text: "Please select an option" },
          { id: 2, value: "a", text: "This is First option" },
          { id: 3, value: "b", text: "Selected Option" },
          {
            id: 4,
            value: { C: "3PO" },
            text: "This is an option with object value",
          },
          { id: 5, value: "d", text: "This one is disabled", disabled: true },
        ],
        dataCards: [
          {
            title: "Total Mantenimientos",
            value: () =>
              this.totalDatos,
            iconName: "hand-point-up",
            color: "rgba(71, 136, 255, 1)",
            tipo: "shield",
            animated: "bounce"
          },
          {
            title: "Mantenimiento Abiertos",
            value: () =>
              this.totalAbierto,
            iconName: "folder-open",
            color: "rgba(22, 162, 117, 1)",
            tipo: "squared",
            animated: "bounce"
          },
          {
            title: "Manetenimientos en Proceso",
            value: () =>
              this.totalProceso,
            iconName: "fas fa-wrench",
            color: "rgba(223, 18, 46, 1)",
            tipo: "shield",
            animated: "bounce"
          },
          {
            title: "Mantenimientos Finalizados",
            value: () =>
              this.totalFinalizado,
            iconName: "fa-solid fa-check",
            color: "rgba(254, 114, 28, 1)",
            tipo: "shield",
            animated: "bounce"
          }
        ],
        tableName: "Persona",
        persona: {},
        empresaId: "",
        listaPersonaUbicacion: [],
        ListasTecnicos: null,
        selected: null,
        ListaMantenimiento: null,
        totalAbierto: 0,
        totalProceso: 0,
        totalFinalizado: 0,
        totalDatos: 0,
        IdMantenimiento: null,
        columns: [
          { label: "Nombre Solicitante", key: "nombreSolicitante", sortable: true, width: '180px' },
          { label: "Nombre Técnico", key: "nombreTecnico", sortable: true, width: '180px' },
          { label: "Lugar", key: "nombre", sortable: true, width: '100px', maxWidth: '100px' },
          { label: "Zonas", key: "Zona", sortable: false, width: '180px' },
          { label: "Tipo Mantenimiento", key: "tipoMantenimiento", sortable: true, width: '60px' },
          { label: "Estado", key: "estado", sortable: true, width: '100px', maxWidth: '100px' },
          { label: "Prioridad", key: "prioridad", sortable: true, width: '100px', maxWidth: '100px' },
          { label: "Descripción", key: "descripcion", sortable: true, width: '300px', maxWidth: '400px' },
          { label: "Opciones", key: "id", sortable: false, width: '250px', maxWidth: '270px' },
        ],
        EmpresasLista: [],
        EmpresaSeleccionada: null
      };
    },
    async created() {
      this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
      this.empresaId = this.persona.empresa.id;
    },
    async mounted() {
      this.$isLoading(true);
      await this.getData();
      await this.getTecnico();
      this.$isLoading(false);
    },
    methods: {
      modal(id) {
        this.IdMantenimiento = id;
        this.$bvModal.show('modal-evidencias');
      },
      async AsignarTecnico(event) {
        const form = event.target;
        let res = await this.$store.dispatch("hl_post", {
          path: `Mantenimiento/AsignarTecnicoMantenimiento/${this.IdMantenimiento}`,
          data: form.tecnico.value
        })
        this.$refs.dataTableLotes.$emit("actualizar");
        res.respuesta ? Swal.fire("Listo!", "Técnico asignado correctamente.", "success") : Swal.fire("Error", "Hubo un problema al asignar el técnico", "error");
        this.$bvModal.hide('modal-evidencias');
      },
      async ObtenerEstadoAbierto() {
        let res = await this.$store.dispatch('hl_get', {
          path: `Mantenimiento/ObtenerCantidadDatos/${this.empresaId}/0`
        })
        if (res.data == null) return 0;
        return res.data;
      },
      async getTecnico() {
        const res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/ListPersonaTecnico/" + this.persona.empresa.id,
        });
        this.ListasTecnicos = res.data.map((item) => {
          return {
            id: item.id,
            nombre: `${item.nombres !== null ? item.nombres : ""} ${item.segundoNombre !== null ? item.segundoNombre : ""} ${item.apellidos !== null ? item.apellidos : ""} ${item.segundoApellido !== null ? item.segundoApellido : ""}`,
          };
        });
      },
      async ObtenerEstadoProceso() {
        let res = await this.$store.dispatch('hl_get', {
          path: `Mantenimiento/ObtenerCantidadDatos/${this.empresaId}/1`
        })
        if (res.data == null) return 0;
        return res.data;
      },
      async ObtenerEstadoFinalizado() {
        let res = await this.$store.dispatch('hl_get', {
          path: `Mantenimiento/ObtenerCantidadDatos/${this.empresaId}/2`
        })
        if (res.data == null) return 0;
        return res.data;
      },
      async fetchData({ filtro, orden, cantDatos, paginacion }) {
        if (!this.empresaId) {
          return { TotalDatos: 0, data: [] };
        }
        if (orden.columna.length > 1) {
          orden.columna = orden.columna[0].toUpperCase() + orden.columna.substring(1);
        }
        let params = {
          filtro: filtro,
          orden: orden,
          cantDatos: cantDatos,
          paginacion: paginacion
        };

        let data = [];
        let total = 0;
        if (this.persona.rol.nivel !== 4) this.EmpresaSeleccionada = null;

        await Promise.all([
          this.$store.dispatch('hl_get', {
            path: `Mantenimiento/ObtenerCantidadDatos/${this.empresaId}?filtro=${params.filtro}&EmpresaConsultar=${this.EmpresaSeleccionada}`
          }),
          this.$store.dispatch('hl_post', {
            path: `Mantenimiento/ObtenerMantenimientos/${this.empresaId}?EmpresaConsultar=${this.EmpresaSeleccionada}`,
            data: params
          })
        ]).then(([cantidadDatos, Datos]) => {
          Datos.data.map(async (item) => {
            let resZonaId = await this.$store.dispatch('hl_get', {
              path: `Mantenimiento/GetMantenimientosZonaId/${item.id}`
            });
            item.Zona = resZonaId.data.map((zona) => zona.nombre).join(', ');
            item.prioridad === 1 ? item.prioridad = 'Baja' : item.prioridad === 2 ? item.prioridad = 'Media' : item.prioridad = 'Alta';

            item.estado === 0 ? item.estado = 'Abierto' : item.estado === 1 ? item.estado = 'Proceso' : item.estado = 'Finalizado';
          });
          if (cantidadDatos.respuesta) {
            total = cantidadDatos.data;
          }
          if (Datos.respuesta) {
            data = Datos.data;
          }
        });

        this.totalAbierto = await this.ObtenerEstadoAbierto();
        this.totalProceso = await this.ObtenerEstadoProceso();
        this.totalFinalizado = await this.ObtenerEstadoFinalizado();
        this.ListaMantenimiento = data
        this.totalDatos = total;
        return { total, data };
      },
      async CambiarEstado(id) {
        await Swal.fire({
          title: '¿Estás seguro que deseas Finalizar este Mantenimiento?',
          text: "¡No podrás revertir esto!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, Finalizar!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.$isLoading(true);
              let cambiar = await this.$store.dispatch("hl_post", {
                path: `Mantenimiento/CambiarEstadoMantenimiento/${id}`,
                data: 2
              })
              if (cambiar.respuesta) {
                this.$isLoading(false);
                Swal.fire("Listo!", "Mantenimiento finalizado correctamente.", "success");
                this.$refs.dataTableLotes.$emit("actualizar");
              } else {
                this.$isLoading(false);
                Swal.fire("Error", "Hubo un problema al finalizar el Mantenimiento", "error");
              }
            } catch (error) {
              await Swal.fire("Error", "Hubo un problema al finalizar el Mantenimiento: " + error, "error");
            }
          }
        });
      },
      async getData() {
        this.$store.dispatch('hl_get', {
          path: 'Empresa/ListEmpresas'
        }).then(response => {
          if (response.respuesta) {
            this.EmpresasLista = response.data;
          }
        })
      },
      async eliminarMantenimiento(id) {
        await Swal.fire({
          title: '¿Estás seguro que deseas eliminar este Mantenimiento?',
          text: "¡No podrás revertir esto!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, borrarlo!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("hl_delete", {
                path: `Mantenimiento/EliminarMantenimientoId/${id}`
              }).then((res) => {
                if (res.respuesta) {
                  Swal.fire("Listo!", "Mantenimiento eliminado correctamente.", "success");
                  this.$refs.dataTableLotes.$emit("actualizar");
                } else {
                  Swal.fire("Error", res.mensaje, "error");
                }
              });
            } catch (error) {
              await Swal.fire("Error", "Hubo un problema al eliminar el Mantenimiento: " + error, "error");
            }
          }
        });
      },
    },
  };
</script>

<style>

  /* tooltip  */
  .font_tooltip {
    font-size: 14px;
    font-style: italic;
  }
</style>
