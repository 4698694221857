var render = function render(){var _vm=this,_c=_vm._self._c;return _c('HXContentCard',{attrs:{"title":"Mantenimientos"}},[_c('div',{staticClass:"text-right px-4 mt-3 mb-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-10"},[_c('CardTable',{attrs:{"data":_vm.dataCards}})],1),_c('div',{staticClass:"col-12 col-md mt-3 mt-md-0"},[_c('BtnAddCardTable',{attrs:{"text":"Agregar Mantenimiento"},on:{"click":function($event){return _vm.$router.push('AgregarMantenimiento')}}})],1)])]),(_vm.persona.rol.nivel === 4)?_c('div',{staticClass:"col-12 col-md-4 pb-3"},[_c('label',{attrs:{"for":"Nombre"}},[_vm._v("Empresa *")]),_c('b-form-select',{attrs:{"name":"Impuesto","options":_vm.EmpresasLista,"value-field":'ID',"text-field":'Nombre',"placeholder":"-- Seleccionar una empresa --","required":""},on:{"change":function($event){return _vm.$refs.dataTableLotes.$emit('actualizar');}},scopedSlots:_vm._u([{key:"selected",fn:function({ option }){return [_vm._v(" "+_vm._s(option?.Impuesto || '-- Seleccionar una empresa --')+" ")]}},{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"null"}},[_vm._v("-- Seleccionar una empresa --")])]},proxy:true}],null,false,1247998585),model:{value:(_vm.EmpresaSeleccionada),callback:function ($$v) {_vm.EmpresaSeleccionada=$$v},expression:"EmpresaSeleccionada"}})],1):_vm._e(),_c('div',{staticClass:"table-responsive px-4 mt-3"},[_c('DataTableLotes',{ref:"dataTableLotes",attrs:{"fetch-data":_vm.fetchData,"columns":_vm.columns,"busqueda-boton":true},scopedSlots:_vm._u([{key:"id",fn:function({ row }){return [_c('div',{staticClass:"d-flex justify-content-center px-0 mx-0"},[(row.nombreTecnico != "Sin asignar")?_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            title: `Proceso`,
            placement: 'top',
            customClass: 'font_tooltip',
          }),expression:"{\n            title: `Proceso`,\n            placement: 'top',\n            customClass: 'font_tooltip',\n          }"}],attrs:{"warn":"","icon":""},on:{"click":function($event){return _vm.$router.push(`DetalleMantenimiento/${row.id}`)}}},[_c('i',{staticClass:"fa-solid fa-list-check"})]):_vm._e(),_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            title: `Editar`,
            placement: 'top',
            customClass: 'font_tooltip',
          }),expression:"{\n            title: `Editar`,\n            placement: 'top',\n            customClass: 'font_tooltip',\n          }"}],attrs:{"disabled":row.estado === "Finalizado","success":"","icon":""},on:{"click":function($event){return _vm.$router.push(`EditarMantenimiento/${row.id}`)}}},[_c('i',{staticClass:"fas fa-solid fa-pen"})]),_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            title: `Cerrar Mantenimiento`,
            placement: 'top',
            customClass: 'font_tooltip',
          }),expression:"{\n            title: `Cerrar Mantenimiento`,\n            placement: 'top',\n            customClass: 'font_tooltip',\n          }"}],attrs:{"disabled":row.estado === "Finalizado" || row.nombreTecnico == "Sin asignar"},on:{"click":function($event){return _vm.CambiarEstado(row.id)}}},[_c('i',{staticClass:"fas fa-solid fa-lock"})]),(row.nombreTecnico == "Sin asignar" && _vm.persona.rol.nivel == 8)?_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            title: `Asignar Técnico`,
            placement: 'top',
            customClass: 'font_tooltip',
          }),expression:"{\n            title: `Asignar Técnico`,\n            placement: 'top',\n            customClass: 'font_tooltip',\n          }"}],attrs:{"warn":"","icon":""},on:{"click":function($event){$event.preventDefault();return (() => _vm.modal(row.id)).apply(null, arguments)}}},[_c('i',{staticClass:"fa-regular fa-user"})]):_vm._e(),_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            title: `Eliminar`,
            placement: 'top',
            customClass: 'font_tooltip',
          }),expression:"{\n            title: `Eliminar`,\n            placement: 'top',\n            customClass: 'font_tooltip',\n          }"}],attrs:{"danger":"","icon":""},on:{"click":function($event){return _vm.eliminarMantenimiento(row.id)}}},[_c('i',{staticClass:"fa-solid fa-trash"})])],1)]}}])})],1),_c('b-modal',{attrs:{"id":"modal-evidencias","size":"lg","hide-footer":""}},[_c('h2',{staticClass:"font-weight-bold"},[_vm._v("Asignar Técnico")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.AsignarTecnico.apply(null, arguments)}}},[_c('div',{staticClass:"mb-3 w-lg-50"},[_c('label',{staticClass:"form-label",attrs:{"for":"tecnico"}},[_vm._v("Técnico")]),_c('b-form-select',{attrs:{"id":"tecnico","options":_vm.ListasTecnicos,"required":"","value-field":"id","text-field":"nombre"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"selected":"","value":null,"disabled":""}},[_vm._v("-- selecciona una opción --")])]},proxy:true}])})],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('vs-button',{attrs:{"type":"submit"}},[_vm._v("Agregar")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }